<template>
  <div class="h-screen flex w-full bg-img">
    <div class="vx-col w-4/5 sm:w-4/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center">
      <vx-card>
        <div class="full-page-bg-color" slot="no-body">
          <div class="vx-row">
            <div class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center">
              <img alt="login" class="mx-auto" src="@/assets/images/pages/forgot-password.png">
            </div>
            <div class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg">
              <div class="p-8">
                <div class="vx-card__title mb-8">
                  <h4 class="mb-4">Enter new Credentials</h4>
                  <p>
                    Please enter the confirmation code that was emailed to you. If you were directly directly from your
                    email, this will already be entered and will wait for you to input your new password.
                  </p>
                </div>

                <vs-input class="w-full" label-placeholder="Confirmation Code" type="text" v-model="code"/>

                <div class="vx-row mb-8">
                  <vs-input
                    class="vx-col w-1/2 mt-6"
                    data-vv-validate-on="blur"
                    label-placeholder="Password"
                    name="password"
                    placeholder="Password"
                    ref="password"
                    type="password"
                    v-model="password"
                    v-validate="'required|min:8'"/>

                  <vs-input
                    class="vx-col w-1/2 mt-6"
                    data-vv-as="password"
                    data-vv-validate-on="blur"
                    label-placeholder="Confirm Password"
                    name="confirm_password"
                    placeholder="Confirm Password"
                    type="password"
                    v-model="confirm_password"
                    v-validate="'min:8|confirmed:password'"/>
                </div>


                <vs-button class="px-4 w-full md:w-auto" to="/login" type="border">Go to Login</vs-button>
                <vs-button @click="confirmAccount" class="float-right px-4 w-full md:w-auto mt-3 mb-8 md:mt-0 md:mb-0">
                  Confirm Reset
                </vs-button>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      code: this.$route.params.code || '',

      password: '',
      confirm_password: '',
    }
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.password !== '' && this.confirm_password !== '';
    },
  },
  created () {

  },
  methods: {

    async confirmAccount () {
      if (!this.validateForm) return;

      if (!this.code) return

      const recaptcha = await this.$recaptcha('login')

      this.$vs.loading();

      this.$store.dispatch('auth/initConfirmReset', {code: this.code, password: this.password, recaptcha})
        .then(response => {
          this.$vs.loading.close();

          if (response.message) {

            this.$gtag.event('auth_fpc_success', {
              event_category: 'authentication',
              event_label: 'auth_fpc_success',
            });

            return this.$router.push('/login')
          }


          this.$gtag.event('auth_fpc_failure_soft', {
            event_category: 'authentication',
            event_label: 'auth_fpc_failure_soft',
          });
        })
        .catch(exception => {
          this.$vs.loading.close();

          if (exception.response) {
            const response = exception.response;

            this.$gtag.event('auth_fpc_failure', {
              event_category: 'authentication',
              event_label: 'auth_fpc_failure',
            });

            if (response.data.data) {
              this.$vs.notify({
                title: 'Failed to Reset Password',
                text: response.data.data,
                color: 'danger'
              })
            }
          }

          return this.$vs.notify({
            title: 'Failed to Reset Password',
            text: 'An unknown error occurred while confirming your password reset',
            color: 'danger'
          })
        })

    }

  }
}
</script>
